.input-box {
  outline: none;
  border: none;
  margin: 0 0 20px 3px;
  padding: 8px 5px 0px 0;
  background: transparent;
  color: rgba(250, 250, 255, 1);
  font-size: 30px;
  border-bottom: 2px solid #ffffff;
  padding: 0 0 5px 5px;
}
.labelText {
  margin-left: 3px;
  color: #ffffff;
}
.input-group {
  display: flex;
  flex-direction: column;
}

.input-box:focus + label {
  color: green;
}
.restart-play-btn {
  width: max-content;
  margin: auto;
}

.restart-play-btn > button {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}
.restart-play-btn > button:focus,
.restart-play-btn > button:active {
  border: none;
  outline: none;
}
.restart-play-btn > button:hover {
  opacity: 0.9;
}

.restart-play-btn > button > span {
  transform: translateY(3px);
}
::placeholder {
  color: #aa827c; /* Firefox */
  opacity: 0.4;
  font-size: medium;
}
