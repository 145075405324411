/* .enablicText1 {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 900;
  font-size: 57px;
  line-height: 73px;
  color: #ffffff;
  width: 100%;
  max-width: 95%;
  margin-left: 13px;
  margin-bottom: 20px;
} */
.num {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 900;
  font-size: 57px;
  color: #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
}
/* .totalWidth {
  width: 90%;
} */
.questionText {
  height: fit-content;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  /* width:100%; */
}
.scoreText {
  justify-content: "flex-end";
  align-items: "center";
  display: "flex";
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  /* width:170px; */
}
.questionTitle {
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 32px;
  line-height: 45px;
  margin-top: 3;
  color: #ffffff;
}
.textarea {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.num-play-btn {
  width: max-content;
  margin: auto;
}

.num-play-btn > button {
  position: relative;
  cursor: pointer;
  display: flex;
  margin: 20px;
  /* align-items: center; */
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}
.num-play-btn > button:focus,
.num-play-btn > button:active {
  border: none;
  outline: none;
}
.num-play-btn > button:hover {
  opacity: 0.9;
}

.num-play-btn > button > span {
  transform: translateY(3px);
}

/* @media only screen and (max-width: 800px) {
  .rectangle {
    display: none;
    margin: 0 auto;
  }
}
@media (min-width: 801px) and (max-width: 1200px) {
  .rectangle {
    height: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .questionText {
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 15px;
    line-height: 33px;
    color: #ffffff;
  }
  .scoreText {
    justify-content: "flex-end";
    align-items: "center";
    display: "flex";
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 15px;
    line-height: 33px;
    color: #ffffff;
  }
  .questionTitle {
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    margin-top: 3;
    color: #ffffff;
  }
  .btn-options {
    width: 100%;
    border-radius: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    background: transparent;
    color: #ffffff;
    padding: 1%;
    border-color: #ffffff;
    box-sizing: border-box;
    padding: 7px;
  }
  .textarea {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
  }
  .totalWidth {
    width: 100%;
  }
} ; */
