.toggleMobileViewTopNavBar {
    display: none;
  }
  .toggleTopNavBar {
    display: block;
  }
  @media only screen and (max-width: 1000px) {
    .toggleMobileViewTopNavBar {
      display: block;
    }
    .toggleTopNavBar {
      display: none;
    }
  }
  .logout-web-view {
    height: calc(100vh - 75px);
    width: 100vw;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .brainImg {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .logout-mobile-view {
    display: none;
  }
  @media (max-width: 600px) {
    .logout-web-view {
      display: none;
    }
    .logout-mobile-view {
      height: fit-content;
      width: 100vw;
      position: relative;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  
    .brainImg {
      width: 100%;
      height: calc(100vh - 71px);
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 950px) {
    .logout-web-view {
      display: none;
    }
    .logout-mobile-view {
      display: inline;
    }
    .brainImg {
      width: 100%;
      height: calc(100vh - 71px);
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }
  