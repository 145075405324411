.assessmentDropdown {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.assessmentGridBackgroundParent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessmentGridBackground {
  width: 90%;
  min-height: 700px;
  max-height: fit-content;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
}

.ellipseGameSection {
  height: max(100%, 100vh);
  position: absolute;
  z-index: -100;
}

.assessmentText {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff;
}

.welcomeText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 10px 0px 0px;
  gap: 8px;
  color: #ffffff;
}

.welcome {
  font-weight: 700;
  font-size: 24px;
}

.gameName {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff;
}

.welcomeHeaderText {
  height: 42.4px;
  width: fit-content;
  color: #fff;
  align-items: center;
}

.welcome {
  font-size: 24px;
  margin: 0;
  width: fit-content;
  min-width: 300px;
  /* padding: 24px; */
}

/* .profileSection {
  width: 35%;
  height: 42.4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */

.header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
}

.gameContentHeader {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
}

.headerFullWidth {
  width: 98%;
  height: 70px;
}

/* .profile {
  width: 50%;
  min-width: 200px;
  color: #fff;
  display: flex;
} */

.view-profile {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
  text-align: right;
  margin-top: 7px;
  cursor: pointer;
}

.firstnameLastname {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 700;
}

.assessmentSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 28px;
  gap: 10px;
  /* margin-left: 20px; */
  width: 95.2%;
  min-height: 700px;
  /* min-width: 1000px; */
  margin-top: 30px;
  margin-bottom: 10px;
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  border: 1px solid #352839;
}

@media (max-width: 1200px) {
  .assessmentSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 28px;
    gap: 10px;
    /* margin-left: 20px; */
    width: 94.5%;
    margin-top: 30px;
    background: rgb(0 0 20 / 71%);
    backdrop-filter: saturate(180%) blur(10px);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
    border-radius: 10px;
  }
}

@media (max-width: 950px) {
  .assessment-section-middle-parent {
    position: relative;
    border-radius: 10.2609px;
    margin: 22px 20px;
    height: 150px;
    display: flex;
    overflow: hidden;
    border: 1px solid #4e4413;
  }
}

@media (max-width: 600px) {
  .assessment-section-middle-parent {
    position: relative;
    width: 90% !important;
    border-radius: 10.2609px !important;
    margin: 22px 20px !important;
    height: 100px !important;
    display: flex !important;
    overflow: hidden !important;
    border: 1px solid #4e4413 !important;
  }
}

.lockChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.assessmentLockGlass {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(0.5px);
  background-image: url("/public/lock.svg");
  background-color: #0f0f16cc;
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}