.enablicText {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 900;
  font-size: 57px;
  line-height: 73px;
  color: #ffffff;
  width: 600px;
  max-width: 95%;
  margin: auto auto 30px auto;
}
.psychometricTestHeading {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-size: 38px;
  line-height: 53px;
  color: #ffffff;
  /* width: 600px; */
  max-width: 95%;
  margin: auto;
  font-weight: 700;
  padding: 0 4rem 2rem 4rem;
}
.testData {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  /* width: 600px; */
  max-width: 95%;
  margin: auto;
  font-weight: 400;
  padding: 0 4rem;
}
.info-validation-container {
  margin-top: 30px;
  color: #7c90f0;
}
.gameRemote {
  display: none;
}
.topNav {
  padding: 1rem 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 950px) {
  .topNav {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  }
  .rectangle {
    display: none;
    margin: 0 auto;
  }
  .ellipseSettings {
    display: none;
  }
  .enablicText {
    display: none;
  }
  .gameRemote {
    display: block;
    width: 50%;
    padding: 10px;
  }
  /* .psychometricTestHeading {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #ffffff;
    width: 400px;
    max-width: 90%;
    margin: auto;
    font-weight: 700;
    padding: 0 2rem;
  }
  .testData {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    width: 400px;
    max-width: 90%;
    margin: auto;
    font-weight: 400;
    padding: 2rem 2rem 0 2rem;
  } */
}

@media (max-width: 600px) {
  .psychometricTestHeading {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #ffffff;
    /* width: 400px; */
    max-width: 90%;
    margin: auto;
    font-weight: 700;
    padding: 0 2rem;
  }
  .testData {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    /* width: 400px; */
    max-width: 90%;
    margin: auto;
    font-weight: 400;
    padding: 2rem 2rem 0 2rem;
  }
}

@media (min-width: 801px) and (max-width: 1200px) {
  .rectangle {
    height: 100%;
    width: 100%;
  }
  .gameRemote {
    display: block;
  }
}

.know-more-button {
  width: 120px;
  padding: 10px;
  border-radius: 16px;
  font-family: "Circular Spotify Text";
  font-size: 15px;
  background: transparent;
  cursor: pointer;
  color: #efefef;
  border-color: #efefef;
}

.home-play-btn {
  width: max-content;
  margin: auto;
}

.home-play-btn > button {
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}
.home-play-btn > button:focus,
.home-play-btn > button:active {
  border: none;
  outline: none;
}
.home-play-btn > button:hover {
  opacity: 0.9;
}

.home-play-btn > button > span {
  transform: translateY(3px);
}
.ellipseSettings {
  height: max(100%, 100vh);
  position: absolute;
  z-index: -100;
}
.nameTest {
  color: #efefef;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  margin-bottom: 10px
}
.nameTest:hover {
  color: #efefef;
}
.textTest {
  margin-left: 11px;
}
.rightTest {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
}
