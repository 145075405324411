
.main-container {
  letter-spacing: 0.2rem;
  color: white;
  display: -webkit-flex;
  -webkit-align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: 80vh;
}

.game {
  padding: 20px 0;
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.game__title {
  -webkit-transform: translateZ(0px);
  font-size: 2.1rem;
  width: 100%;
}

.game__score {
  margin-top: 1.3rem;
  width: 100%;
  font-size: 0.8rem;
}

.board {
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.game__hole {
  margin: 2px;
  overflow: hidden;
  width: 30vw;
  height: 30vw;
}

.game__mound {
  /* background: url(../public/assets/mole-hole.svg); */
  background: url(../../../../../public/assets/mole-hole.svg);
  background-position: right 5px bottom 0px;
  background-size: 95%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
  bottom: 99%;
  pointer-events: none;
  backface-visibility: hidden;
}

.game__mole {
  background-image: url(../../../../../public/assets/mole.png);
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: top;
  height: 100%;
  transform: translate(0, 50%);
  transition: all 0.4s;
  cursor: pointer;
}

.game__whack {
  width: 100%;
  height: 100%;
}

.game__cross {
  background-image: url(../../../../../public/assets/mole-laugh.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  width: 100%;
  height: 100%;
}

.game__button-container {
  width: 100%;
}
.game__start-button {
  width: max-content;
  margin: auto;
}

.game__start-button > button {
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}
.game__start-button > button:focus,
.game__start-button > button:active {
  border: none;
  outline: none;
}
.game__start-button > button:hover {
  opacity: 0.9;
}

.game__start-button > button > span {
  transform: translateY(3px);
}
.game__you-scored {
  font-size: 1.1rem;
}

.game__game-over {
  background-image: url(../../../../../public/assets/wavy-border.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 280px;
  height: 190px;
  margin-bottom: 30px;
}

.game__game-over-header {
  margin: 62px 0 -10px 0;
}

.no-background {
  background: none;
}

@media screen and (min-width: 400px) {
  .game__mole {
    background-size: 60px;
  }
}

@media screen and (min-width: 500px) {
  .game__game-over {
    width: 320px;
    height: 200px;
  }

  .game__game-over-header {
    margin: 70px 0 -10px 0;
  }

  .game__cross {
    background-size: 75px;
  }

  .game {
    width: 400px;
  }

  .game__mole {
    background-size: 65px;
  }

  .game__hole {
    width: 120px;
    height: 120px;
  }
}
