.ellipseGameSection {
  height: max(100%, 100vh);
  position: absolute;
  z-index: -100;
}

.categoryText {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff;
}

.welcomeText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 10px 0px 0px;
  gap: 8px;
  color: #ffffff;
}

.welcome {
  font-weight: 700;
  font-size: 24px;
}

.gameName {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff;
}

.credits {
  display: flex;
  font-size: 15px;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff;
}

.welcomeHeaderText {
  height: 42.4px;
  width: fit-content;
  color: #fff;
  align-items: center;
}

.welcome {
  font-size: 24px;
  margin: 0;
  width: fit-content;
  min-width: 300px;
  /* padding: 24px; */
}

.searchSection {
  width: 335px;
  height: 42.4px;
  align-items: center;
}

.profileSection {
  width: 35%;
  height: 42.4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}

.navbar-profile-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(233, 233, 233, 0.23);
  border: 1px solid rgb(255, 214, 0);
  margin-right: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
}

.gameContentHeader {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.19);
}

.searchAndNotification {
  display: flex;
}

.headerFullWidth {
  width: 98%;
  height: 70px;
}

.profile {
  /* width: 50%; */
  min-width: fit-content;
  color: #fff;
  display: flex;
}

.search-container-game-section {
  width: 113px;
  padding-right: 18px;
}

.search-container-game-section > input {
  width: 113px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 40px;
  padding: 12px 38px 12px 16px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.search-container-game-section > input:focus,
.search-container-game-section > input:active {
  outline: none;
}

.view-profile {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
  text-align: right;
  margin-top: 7px;
  cursor: pointer;
}

.notification {
  width: 30px;
  margin-left: 30px;
}

.CaretDown {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.firstnameLastname {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 700;
}

.profileIcon {
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playicon {
  position: absolute;
  top: 10px;
  /* top: 26px; */
  right: 14px;
}

.categorySection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 28px;
  gap: 10px;
  /* margin-left: 20px; */
  width: 95.2%;
  /* min-width: 1000px; */
  margin-top: 30px;
  margin-bottom: 10px;
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  border: 1px solid #352839;
}

.highScore {
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  width: 100%;
  height: 60px;
  /* margin-top: 10px; */
  background: #352839;
  color: #ffffff;
  /* padding-left: 70%; */
  font-family: Circular Spotify Text;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* border-bottom: 0; */
  align-items: center;
  /* border-radius: 16px; */
}

.leaderboard {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 45px;
  color: #ffffff;
  width: 100%;
  /* max-width: 95%; */
  padding-left: 14px;
  /* margin: auto auto 30px 30px; */
}

.leaderBoardCard {
  width: 70%;
  max-width: 942px;
  min-height: 215px;
  max-height: calc(85vh - 68px);
  justify-content: center;
  border-radius: 20px;
  background: #170d1c !important;
}

.leaderboardHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  margin-top: 10px;
}

.leaderboardTableCell {
  font-family: Circular Spotify Text !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #ffffff !important;
  width: 100% !important;
  /* max-width: 95% !important; */
  /* margin: auto auto 30px 30px !important; */
  border-bottom: 1px solid #4b5067 !important;
}

.leaderScoreSection {
  width: 30%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .categorySection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 28px;
    gap: 10px;
    /* margin-left: 20px; */
    width: 94.5%;
    margin-top: 30px;
    background: rgb(0 0 20 / 71%);
    backdrop-filter: saturate(180%) blur(10px);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
    border-radius: 10px;
  }

  .leaderScoreSection {
    width: 35%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leaderboard {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 45px;
    color: #ffffff;
    width: 100%;
  }

  .leaderBoardCard {
    width: 80%;
    max-width: 942px;
    min-height: 215px;
    max-height: calc(85vh - 68px);
    justify-content: center;
    border-radius: 20px;
    background: #170d1c !important;
  }

  .leaderboardHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
  }

  .leaderboardTableCell {
    /* font-family: Circular Spotify Text !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.3rem !important;
    line-height: 28px !important;
    color: #ffffff !important;
    width: 100% !important;
    max-width: 95% !important;
    margin: auto auto 30px 30px !important;
    border-bottom: 1px solid #4b5067 !important; */
    color: #ffffff !important;
  }

  .highScore {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    background: #352839;
    color: #ffffff;
    font-family: Circular Spotify Text;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 28px;
    align-items: center;
  }
}

.leaderBoardLoaderCss {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
}
