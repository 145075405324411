.sign-lang-play-btn > button {
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}
.sign-lang-play-btn > button:focus,
.sign-lang-play-btn > button:active {
  border: none;
  outline: none;
}
.sign-lang-play-btn > button:hover {
  opacity: 0.9;
}

.sign-lang-play-btn > button > span {
  transform: translateY(3px);
}
.correctText {
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}