.MathsQuiz-header-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 650px;
}

.MathsQuiz-header-bar span {
  flex: 1;
}
