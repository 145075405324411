.enablicText1 {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 900;
  font-size: 57px;
  line-height: 73px;
  color: #ffffff;
  /* width: 100%; */
  max-width: 95%;
  /* margin-left: 13px; */
  margin-bottom: 20px;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 1rem;
  margin-left: 0;
  border-bottom-right-radius: 18px;
}
.questionText {
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}
.scoreText {
  justify-content: "flex-end";
  align-items: "center";
  display: "flex";
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}
.questionTitleTest {
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  margin-top: 3;
  color: #ffffff;
}
.btn-options {
  border-radius: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  background: transparent;
  color: #ffffff;
  padding: 1%;
  border-color: #ffffff;
}
.toggleEnablicText {
  display: inline;
}
@media only screen and (max-width: 950px) {
  .rectangle {
    display: none;
    margin: 0 auto;
  }
}
@media (min-width: 801px) and (max-width: 1200px) {
  .rectangle {
    height: 100%;
  }
}
@media only screen and (max-width: 950px) {
  .questionText {
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 15px;
    line-height: 33px;
    color: #ffffff;
  }
  .scoreText {
    justify-content: "flex-end";
    align-items: "center";
    display: "flex";
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 15px;
    line-height: 33px;
    color: #ffffff;
  }
  .questionTitleTest {
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    margin-top: 3;
    color: #ffffff;
  }
  .btn-options {
    width: 100%;
    border-radius: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    background: transparent;
    color: #ffffff;
    padding: 1%;
    border-color: #ffffff;
    box-sizing: border-box;
    padding: 7px;
  }
  .toggleEnablicText {
    display: none;
  }
  .questionTitleTest {
    font-family: "Circular Spotify Text";
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    margin-top: 1rem;
    color: #ffffff;
  }
}
.questionHeight {
  height: calc(100% - 51px);
  overflow-y: auto;
  padding-bottom: 1rem;
  box-sizing: border-box;
}
