.enablicJourney {
  display: flex;
  gap: 14px;
  margin-top: 24px;
}

.enablicJourney .progress-container {
  font-family: "Circular Spotify Text";
  margin-left: 10px;
  padding: 30px 6px;
  width: 250px;
  background: #1a0f1f;
  border: 1px solid #352839;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  height: 771px;
  overflow: auto;
}

.enablicJourney .progress-container::-webkit-scrollbar {
  width: 8px;
}

.enablicJourney .progress-container::-webkit-scrollbar-track {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #1a0f1f;
  border: 1px solid #352839;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
}

.enablicJourney .progress-container::-webkit-scrollbar-thumb {
  background: #97baf9;
  border: 0.986357px solid #352839;
  box-shadow: 0px 3.94543px 12.8226px rgba(0, 0, 0, 0.31);
  border-radius: 9.86357px;
}

.enablicJourney .progress-container .item {
  background-color: #3f3543;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 17px;
  border: 1px solid #352839;
  border-radius: 10px;
  cursor: pointer;
}

.enablicJourney .progress-container .item .title {
  font-size: 14px;
  font-weight: 600;
}
.enablicJourney .progress-container .item .subtitle {
  font-size: 10px;
  margin-top: 0.5em;
  color: rgba(255, 255, 255, 0.47);
  font-weight: 400;
}

.enablicJourney .progress-container .progress-stat {
  min-height: 24px;
  border-left: 3px solid rgba(255, 255, 255, 0.08);
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enablicJourney .progress-container .progress-stat.open {
  padding: 9px 0 32px 11px;
}

.enablicJourney .progress-container .progress-stat .your-progress {
  font-size: 12px;
  margin: 0;
}

.enablicJourney .progress-container .progress-stat .now-playing {
  font-size: 10px;
  color: rgba(236, 236, 236, 0.44);
  margin: 0;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.enablicJourney .progress-container .progress-stat:last-child {
  border-left: none;
}

.enablicJourney .progress-container .progress-stat .right {
  position: relative;
  border: 3.6px solid rgba(255, 255, 255, 0.24);
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: content-box;
}

.enablicJourney .progress-container .progress-stat .right .percentage {
  font-size: 8px;
}

.enablicJourney .score-container {
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  width: 100%;
  padding: 0 14px;
  position: relative;
}

.enablicJourney .score-container::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.08);
  top: 100%;
  left: -1px;
}

.enablicJourney .score-container .content {
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
  border: 1px solid #352839;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
}

.enablicJourney .score-container .content .icon {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  /* background-color: #ca4963; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.enablicJourney .score-container .content .icon > svg {
  width: 57px;
  height: 57px;
}
.enablicJourney .score-container .content .icon > svg,
.enablicJourney .score-container .content .icon > svg > path {
  fill: #fff;
}

.enablicJourney .score-container .content .title {
  font-size: 32px;
  margin-top: 24px;
  font-weight: 600;
}

.enablicJourney .score-container .content .subtitle {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.47);
  margin-top: 18px;
}

.enablicJourney .score-container .content .scores {
  width: 400px;
  margin-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: center;
}

.enablicJourney .score-container .content .scores .left {
  padding: 18px 54px 24px 0;
  font-size: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
}
.enablicJourney .score-container .content .scores .left p,
.enablicJourney .score-container .content .scores .right p {
  text-align: center;
}
.enablicJourney .score-container .content .scores .left p:first-child,
.enablicJourney .score-container .content .scores .right p:first-child {
  color: rgba(255, 255, 255, 0.47);
}
.enablicJourney .score-container .content .scores .right {
  font-size: 20px;
  padding: 18px 0 24px 54px;
}
.loaderCss {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 71px);
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
}
