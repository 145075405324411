.dashboard-content {
  display: flex;
  color: #fff;
  font-family: "Circular Spotify Text";
}

.dashboard-content .main {
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
}

.dashboard-content .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-content .header .text {
  font-size: 26px;
  padding-left: 0.5em;
  margin: 27px 0 0;
}

.dashboard-content .header .search-container {
  padding-right: 38px;
  position: relative;
}

.dashboard-content .header .search-container>input {
  width: 213px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.07);
  border-radius: 40px;
  padding: 12px 38px 12px 16px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.dashboard-content .header .search-container>input:focus,
.dashboard-content .header .search-container>input:active {
  outline: none;
}

.dashboard-content .first {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 10.2609px;
  padding: 10px;
  margin: 22px 28px 0 16px;
}

.dashboard-content .first .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #7da7fb;
  border-radius: 10px 10px 0px 0px;
}

.dashboard-content .first .top .right {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
}

.dashboard-content .first .top h1 {
  font-size: 42px;
  margin: 0;
  padding: 24px;
}

.dashboard-content .first .top p {
  margin: 0 24px;
  width: 390px;
  line-height: 24px;
  padding-bottom: 14px;
  font-weight: 400;
}

.dashboard-content .first .bottom {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 4px 24px);
  backdrop-filter: blur(90px);
  background: linear-gradient(93.5deg, #1a0f1f 4.67%, #1c032a00 96.2%);
  border: 1px solid #352839;
  box-shadow: 0px 4px 13px rgb(0 0 0 / 31%);
  border-radius: 10px;
}

.dashboard-content .first .bottom .left {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 24px;
}

.dashboard-content .first .bottom .left .dot {
  width: 5px;
  height: 5px;
  background-color: #fff;
  border-radius: 50%;
}

.dashboard-play-btn {
  width: max-content;
  /* margin-right: 2rem; */
}

.dashboard-play-btn>button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: radial-gradient(98.44% 1567.43% at 97.46% 50.27%,
      #7da9f8 0%,
      #7c90f0 100%);
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  margin: 20px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}

.dashboard-play-btn>button:focus,
.dashboard-play-btn>button:active {
  border: none;
  outline: none;
}

.dashboard-play-btn>button:hover {
  opacity: 0.9;
}

.dashboard-play-btn>button>span {
  transform: translateY(3px);
}

.workouts {
  height: fit-content;
  width: 100%;
  position: relative;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.lockGlass {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  background-image: url("/public/lock.svg");
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: center;
}

.workoutsMobile {
  height: fit-content;
  width: 100%;
  position: relative;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.lockGlassMobile {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  background-image: url("/public/lock.svg");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  padding-bottom: 10px;
}

.dashboard-content .second {
  /* background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%); */
  border: 1px solid #4e4413;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  margin: 22px 28px 0 16px;
}

.dashboard-content .second .top {
  background: #170d1c;
  border: 1px solid #4e4413;
  border-radius: 10px 10px 0px 0px;
  height: 54px;
  padding-left: 18px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.dashboard-content .second .workout-item {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #352839;
}

.dashboard-content .second .workout-item:last-of-type {
  border: none;
}

.dashboard-content .second .workout-item .icon {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.dashboard-content .second .workout-item .text {
  margin-left: 11px;
}

.dashboard-content .second .workout-item .text .name {
  color: #efefef;
  font-weight: 700;
}

.dashboard-content .second .workout-item .text .description {
  color: #b4adad;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 400;
}

.dashboard-content .second .workout-item .unlock {
  cursor: pointer;
  width: 76px;
  height: 36px;
  background: rgba(255, 214, 0, 0.04);
  border: 1px solid #ffd600;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffd600;
  font-size: 14px;
  margin-left: auto;
  font-weight: 600;
  padding: 8px 14px;
}

.ellipseDashboard {
  height: max(100%, 100vh);
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
}

.ellipseBlue {
  position: absolute;
  right: -100px;
  z-index: -100;
  top: 0px;
  right: 0;
}

.webView {
  height: fit-content;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.tabViewHeader {
  display: none;
}

.mobileView {
  display: none;
}

@media (max-width: 600px) {
  .webView {
    display: none;
  }

  .mobileView {
    display: inline;
  }

  .dashboard-play-btn {
    width: max-content;
    margin-right: 1rem;
    z-index: 0;
  }

  .dashboard-play-btn>button {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: radial-gradient(98.44% 1567.43% at 97.46% 50.27%,
        #7da9f8 0%,
        #7c90f0 100%);
    border-radius: 23.8272px;
    border: none;
    outline: none;
    padding: 5px;
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    width: 150px;
    box-shadow: 0px 0px 8px #7c90f0;
    z-index: 0;
  }

  .dashboard-play-btn>button:focus,
  .dashboard-play-btn>button:active {
    border: none;
    outline: none;
  }

  .dashboard-play-btn>button:hover {
    opacity: 0.9;
  }

  .dashboard-play-btn>button>span {
    transform: translateY(3px);
  }

  .main-dashboard-content-mobile-header {
    height: 30vh;
    background: #7da7fb;
    align-items: center;
    border-radius: 5px;
    margin: 5% 0 5% 0;
  }

  .main-dashboard-content-mobile-header-heading {
    font-family: Circular Spotify Text !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 33px !important;
    color: #ffffff !important;
  }

  .main-dashboard-content-mobile-header-text {
    font-family: Circular Spotify Text !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #ffffff !important;
  }

  .main-dashboard-content-mobile-header-img {
    width: 90px !important;
    height: 130px !important;
    padding: 5px !important;
    display: flex !important;
  }

  .main-dashboard-content-mobile-header-lower {
    width: 100%;
    height: 10vh;
    display: flex;
  }

  .main-dashboard-content-mobile-header-lower-bg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    border: 1px solid #352839;
    box-shadow: 0px 4px 13px rgb(0 0 0 / 31%);
  }

  .main-dashboard-content-mobile-middle {
    width: 100%;
    height: 60vh;
  }

  .main-dashboard-content-mobile-middle-header {
    width: 90%;
    height: 50px;
    background: #170d1c;
    border: 1px solid #4e4413;
    border-radius: 10.2609px 10.2609px 0 0;
    margin: 0px 20px;
  }

  .main-dashboard-content-mobile-middle-header-bg {
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #352839;
    background: rgb(23, 13, 28);
    margin: 0px 20px;
  }

  .main-dashboard-content-mobile-middle-header-img-text {
    color: #ffffff;
    font-size: 16px !important;
    font-weight: 700 !important;
    display: flex !important;
    align-items: center !important;
  }

  .main-dashboard-content-mobile-unlock-button {
    cursor: pointer;
    width: 76px;
    height: 36px;
    background: rgba(255, 214, 0, 0.04);
    border: 1px solid #ffd600;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    color: #ffd600;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 14px;
  }

  .main-dashboard-content-mobile-unlock {
    width: 100%;
    height: 50px;
    display: flex;
    padding-left: 10px;
    align-items: center;
  }

  .main-dashboard-content-mobile-desc {
    width: 100% !important;
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    font-family: Circular Spotify Text !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #ffffff !important;
    padding: 0 10px !important;
  }

  .main-dashboard-content-mobile-name {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    color: #efefef;
    font-weight: 700;
    font-size: 14px;
    padding-left: 10px;
  }

  .main-dashboard-content-mobile-brain {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-dashboard-content-mobile-text-brain {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .main-dashboard-content-mobile-middle-content {
    width: 100%;
    height: 155px;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    border-bottom: 1px solid #352839;
    border: 1px solid #4e4413;
  }

  .main-dashboard-content-mobile-middle-bg {
    height: 155px;
    padding: 0 50px;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
  }

  .main-dashboard-content-mobile-text-brain-parent {
    height: 50px;
    align-items: center;
  }
}

@media (max-width: 950px) {
  .webView {
    display: none;
  }

  .mobileView {
    display: inline;
  }

  .dashboard-play-btn {
    width: max-content;
    margin-right: 1rem;
    z-index: 0;
  }

  .dashboard-play-btn>button {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: radial-gradient(98.44% 1567.43% at 97.46% 50.27%,
        #7da9f8 0%,
        #7c90f0 100%);
    border-radius: 23.8272px;
    border: none;
    outline: none;
    padding: 5px;
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    width: 150px;
    box-shadow: 0px 0px 8px #7c90f0;
    z-index: 0;
  }

  .dashboard-play-btn>button:focus,
  .dashboard-play-btn>button:active {
    border: none;
    outline: none;
  }

  .dashboard-play-btn>button:hover {
    opacity: 0.9;
  }

  .dashboard-play-btn>button>span {
    transform: translateY(3px);
  }

  .main-dashboard-content-mobile-header {
    height: 30vh;
    background: #7da7fb;
    align-items: center;
    border-radius: 5px;
    margin: 5% 0 5% 0;
  }

  .main-dashboard-content-mobile-header-heading {
    font-family: Circular Spotify Text;
    font-weight: 700;
    font-size: 23px;
    line-height: 70px;
    color: #ffffff;
  }

  .main-dashboard-content-mobile-header-text {
    font-family: Circular Spotify Text;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
  }

  .main-dashboard-content-mobile-header-img {
    width: 100px !important;
    height: 150px !important;
    padding: 5px !important;
    display: flex !important;
  }

  .main-dashboard-content-mobile-header-lower {
    width: 100%;
    height: 10vh;
    display: flex;
  }

  .main-dashboard-content-mobile-header-lower-bg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    border: 1px solid #352839;
    box-shadow: 0px 4px 13px rgb(0 0 0 / 31%);
  }

  .main-dashboard-content-mobile-middle {
    width: 100%;
    height: 60vh;
  }

  .main-dashboard-content-mobile-middle-header {
    width: 90%;
    height: 50px;
    background: #170d1c;
    border: 1px solid #4e4413;
    border-radius: 10.2609px 10.2609px 0 0;
    margin: 0px 20px;
  }

  .main-dashboard-content-mobile-middle-header-bg {
    width: 90%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #352839;
    background: rgb(23, 13, 28);
    margin: 0px 20px;
  }

  .main-dashboard-content-mobile-middle-header-img-text {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .main-dashboard-content-mobile-unlock-button {
    cursor: pointer;
    width: 76px;
    height: 36px;
    background: rgba(255, 214, 0, 0.04);
    border: 1px solid #ffd600;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    color: #ffd600;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 14px;
  }

  .main-dashboard-content-mobile-unlock {
    width: 100%;
    height: 50px;
    display: flex;
    padding-left: 10px;
    align-items: center;
  }

  .main-dashboard-content-mobile-desc {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: Circular Spotify Text;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    padding: 0 10px;
  }

  .main-dashboard-content-mobile-name {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    color: #efefef;
    font-weight: 700;
    font-size: 18px;
    padding-left: 17px;
  }

  .main-dashboard-content-mobile-brain {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-dashboard-content-mobile-text-brain {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .main-dashboard-content-mobile-middle-content {
    width: 100%;
    height: 155px;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    border-bottom: 1px solid #352839;
    border: 1px solid #4e4413;
    padding: 12px 0;
  }

  .main-dashboard-content-mobile-middle-bg {
    height: 155px;
    padding: 0 60px;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
  }

  .main-dashboard-content-mobile-text-brain-parent {
    height: 50px;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .dashboard-content .header {
    display: none;
  }

  .tabViewHeader {
    display: block;
  }
}