.memory-parent {
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  /* background: #1b1523; */
  color: #fff;
  max-width: 860px;
  margin: 40px auto;
  width: 55vw;
}
.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
