.ellipse1 {
  height: max(100%, 100vh);
  position: absolute;
  right: -300px;
  z-index: -100;
  top: -303px;
}

.ellipse2 {
  height: max(100%, 100vh);
  position: absolute;
  left: -200px;
  z-index: -100;
  top: -303px;
}

.home-container {
  width: 100%;
  height: 100%;
  /* margin-top: 50px; */
  overflow-x: hidden;
}

.home-header {
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 900;
  font-size: 57px;
  line-height: 73px;
  text-align: center;
  color: #ffffff;
  width: 819px;
  max-width: 95%;
  margin: auto;
  padding-top: 10px;
}

.home-header>span {
  color: #ffe3cd;
}

.home-curved-line {
  text-align: center;
  transform: translateY(-15px);
  width: 68vw;
  margin: auto;
}

.home-curved-line>svg {
  width: 100%;
}

.home-sub-header {
  margin: auto;
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: 607px;
  max-width: 95%;
}

.home-mid-section {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  transform: translateY(-100px);
}

.home-mid-section svg {
  max-width: 85vw;
  height: max-content;
}

.home-left-brain,
.home-right-brain {
  position: relative;
}

.home-play-btn {
  width: max-content;
  margin: auto;
}

.home-play-btn>button {
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: radial-gradient(98.44% 1567.43% at 97.46% 50.27%,
      #7da9f8 0%,
      #7c90f0 100%);
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}

.game-section-return-btn {
  margin-bottom: 20px;
}

.home-play-btn>button:focus,
.home-play-btn>button:active {
  border: none;
  outline: none;
}

.home-play-btn>button:hover {
  opacity: 0.9;
}

.home-play-btn>button>span {
  transform: translateY(3px);
}

@media screen and (max-width: 1150px) {
  .home-mid-section {
    transform: translateY(0);
  }
}

.toggleMobileViewTopNavBar {
  display: none;
}

.toggleTopNavBar {
  display: block;
}

@media (max-width: 1000px) {
  .ellipse1 {
    display: none;
  }

  .ellipse2 {
    display: none;
  }

  .home-play-btn {
    width: max-content;
    margin: auto;
    /* padding: 10px 0 10px 0; */
  }

  .home-play-btn>button {
    position: relative;
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: radial-gradient(98.44% 1567.43% at 97.46% 50.27%,
        #7da9f8 0%,
        #7c90f0 100%);
    border-radius: 23.8272px;
    border: none;
    outline: none;
    padding: 40px 0 0 0;
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 21.2489px;
    line-height: 30px;
    color: #fff;
    width: 200px;
    box-shadow: 0px 0px 8px #7c90f0;
    z-index: 1;
  }

  .home-play-btn>button:focus,
  .home-play-btn>button:active {
    border: none;
    outline: none;
  }

  .home-play-btn>button:hover {
    opacity: 0.9;
  }

  .home-play-btn>button>span {
    transform: translateY(3px);
  }

  .toggleMobileViewTopNavBar {
    display: block;
  }

  .toggleTopNavBar {
    display: none;
  }

  .home-header {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    width: 819px;
    max-width: 95%;
    margin: auto;
  }

  .home-header>span {
    color: #ffe3cd;
  }

  .home-sub-header {
    margin: auto;
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    width: 607px;
    max-width: 95%;
  }
}

@media (max-width: 750px) {
  .ellipse1 {
    display: none;
  }

  .ellipse2 {
    display: none;
  }

  .toggleMobileViewTopNavBar {
    display: block;
  }

  .toggleTopNavBar {
    display: none;
  }

  .home-right-brain {
    display: none;
  }

  .home-header {
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    width: 819px;
    max-width: 95%;
    margin: auto;
  }

  .home-header>span {
    color: #ffe3cd;
  }

  .home-sub-header {
    margin: auto;
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    width: 607px;
    max-width: 95%;
  }
}