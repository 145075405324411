.MathsQuiz {
  text-align: center;
  background: rgba(92, 94, 112, 0.384);
  padding: 20px;
  border-radius: 12px;
  min-height: 400px;
}

.MathsQuiz-logo {
  /* animation: MathsQuiz-logo-spin infinite 20s linear; */
  height: 40vmin;
  pointer-events: none;
  display: block;
  margin: auto;
}

.MathsQuiz-header {
  /* background-color: #1a0f1f; */
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MathsQuiz-brandname i {
  font-size: 3em;
}

.MathsQuiz-brandname h3 {
  font-style: italic;
  margin: 0;
  font-weight: 600;
}

.MathsQuiz-brandname h1 {
  font-family: Circular Spotify Text;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
  color: #7c90f0;
}

.MathsQuiz-footer {
  background: #424855;
  color: whitesmoke;
  padding: 0.5em;
}

.MathsQuiz-footer small {
  font-size: 0.6em;
}

.MathsQuiz-footer a {
  color: #61dafb;
  text-decoration: none;
}

.MathsQuiz-link {
  background: #61dafb;
  padding: 1em;
  border: 1px solid #057897;
  border-radius: 3px;
  color: white;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.MathsQuiz-link:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.MathsQuiz-input {
  padding: 0.5em;
  border-radius: 12px;
  border: 2px solid #303747;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  background: #1e0e25;
  color: #ffffff;
}

.MathsQuiz-input--sm {
  padding: 0.3em !important;
}

.correct-answer {
  /*background: linear-gradient(120deg, rgb(49, 205, 97) 30%, rgb(94, 209, 10) 70%);*/
  color: rgb(49, 205, 97);
}

.wrong-answer {
  /*background: linear-gradient(120deg, rgb(255, 37, 99) 30%, rgb(255, 80, 7) 70%);*/
  color: rgb(255, 37, 99);
}

.answer {
  font-size: 5em;
  /* padding: 1em 2em; */
  border-radius: 100px;
  animation-name: answerModal;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.show-up {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes show {
  from {
    opacity: 0.85;
  }
  to {
    opacity: 1;
  }
}

@keyframes answerModal {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes MathsQuiz-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
