.puzzle {
  text-align: center;
}

.puzzle-play-btn {
  width: max-content;
  margin: auto;
}

.puzzle-play-btn > button {
  position: relative;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
  margin: 20px;
}
.puzzle-play-btn > button:focus,
.puzzle-play-btn > button:active {
  border: none;
  outline: none;
}
.puzzle-play-btn > button:hover {
  opacity: 0.9;
}

.puzzle-play-btn > button > span {
  transform: translateY(3px);
}

.puzzle-header {
  /* background-color: #282c34; */
  /* min-height: 70vh; */
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
slider-board {
  margin-top: 18px;
  width: 100%;
}

.slider-board div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-msg {
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.puzzle-btn-new-game {
  display: flex;
  flex-direction: column;
}
.puzzle-btn{
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 23.8272px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}
.slider-board div span {
  align-items: center;
  display: flex;
  align-items: center;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5f5e88;
  margin: 0;
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 14px;
  /* display: inline-block; */
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0px 0 rgba(255, 255, 255, 0.4),
    inset 0 0 8px 0 rgba(0, 0, 0, 0.4), inset 0 0 3px 5px rgba(0, 0, 0, 0.05),
    2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider-board div div {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5f5e88;
  margin: 0;
  margin-bottom: 2px;
  margin-left: 2px;
  padding: 14px;
  display: inline-block;
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0px 0 rgba(255, 255, 255, 0.4),
    inset 0 0 8px 0 rgba(0, 0, 0, 0.4), inset 0 0 3px 5px rgba(0, 0, 0, 0.05),
    2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider-board div span.empty,
.slider-board div div.empty {
  width: 46px;
  margin: 0;
  padding: 14px;
  margin-bottom: 2px;
  margin-left: 2px;
  background: transparent;
  box-shadow: none;
}
