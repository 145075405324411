@media only screen and (max-width: 950px) {
  .rectangle {
    display: none;
    margin: 0 auto;
  }
}

@media (min-width: 801px) and (max-width: 1200px) {
  .rectangle {
    height: 100%;
  }
}

.testHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 45px;
  /* color: #3a3a39; */
  color: #efefef;
  width: 100%;
  max-width: 95%;
  margin: auto 30px 30px 30px;
}

.totalScore {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.3);
  color: #efefef;
  font-family: Circular Spotify Text;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 0;
  align-items: center;
}

.comments {
  width: 60%;
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #efefef;
  margin: 1%;
  margin-left: 30px;
}

.home-play-btn {
  width: max-content;
  margin: auto;
}

.toggleEnablicText {
  display: inline;
}

.home-play-btn > button {
  position: relative;
  /* margin-top: 30px;
  margin-bottom: 10px; */
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  margin-top: 0;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 15.88px;
  border: none;
  outline: none;
  padding: 10px;
  font-family: "Circular Spotify Text";
  font-weight: 700;
  font-size: 21.2489px;
  line-height: 30px;
  color: #fff;
  width: 200px;
  box-shadow: 0px 0px 8px #7c90f0;
  z-index: 1;
}

.home-play-btn > button:focus,
.home-play-btn > button:active {
  border: none;
  outline: none;
}

.home-play-btn > button:hover {
  opacity: 0.9;
}

.home-play-btn > button > span {
  transform: translateY(3px);
}

.bottom-section {
  display: flex;
  padding: 15px 0 5px 0;
  width: "100%";
}

@media only screen and (max-width: 600px) {
  .testHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #efefef;
    width: 100%;
    max-width: 95%;
    margin: auto 0 30px 0;
  }

  .totalScore {
    display: flex;
    justify-content: center;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    /* width: 100%; */
    height: 50px;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.3);
    color: #efefef;
    /* padding-left: 70%; */
    font-family: Circular Spotify Text;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    /* max-width: 95%; */
    border-bottom: 0;
    align-items: center;
  }

  .comments {
    width: 100%;
    /* justify-content: center; */
    font-family: "Circular Spotify Text";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #efefef;
    padding-right: 3%;
    margin-bottom: 3%;
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 10px; */
  }

  .toggleEnablicText {
    display: none;
  }
}
