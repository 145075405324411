::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 20px;
}
.toggleMobileViewTopNavBar {
  display: none;
}
.toggleTopNavBar {
  display: block;
}
@media only screen and (max-width: 1000px) {
  .toggleMobileViewTopNavBar {
    display: block;
  }
  .toggleTopNavBar {
    display: none;
  }
}
.loaderCss {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
}