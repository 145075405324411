body {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1a0f1f;
  background-image: url("/public//assets/5470185.jpg");
  background-position: top;
  /* background-size: 100% 100%; */
  background-size: cover;

  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden !important;
  width: 100wh;

}
a {
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
