.card {
  position: relative;
}
.card:hover {
  color: #d1d1cd;
  box-shadow: 0 0 10px #d1d1cd;
  text-shadow: 0 0 10px #d1d1cd;
  transform: scale(1.02);
  transition-delay: 0.1s;
}
.card img {
  width: 100%;
  display: block;
  border: 2px solid #fff;
  border-radius: 6px;
}
.card .front {
  transform: rotateY(90deg);
  position: absolute;
}
.flipped .front {
  transform: rotateY(0deg);
}

.card .back {
  transition: all ease-in 0.05s;
  transition-delay: 0.1s;
}
.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}
