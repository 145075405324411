h3{
    margin: 0;
    font-weight: 400;
}

.progress-container-with-image{
    height: 100%;
    /* background: yellow; */
    position: relative;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.circular-progress-container{
    /* background: red; */
    width:140px;
    height: 140px;
    /* font-size: 1.1rem; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 300;
}

.inner-circle{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    background: #2575c0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    color: rgb(200, 234, 254);
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.inner-circle-top-child{
    margin-top: 18px;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.inner-circle-bottom-child {
    /* background: white; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
}

.level-star-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
}

.level-star-container > span {
    position: absolute;
    color: black;
    font-size: .8;
    margin-top: 5px;
}

.inner-circle-bottom-child > span{
    display: block;
    margin: 0 1px;
    margin-top: 3px;
}

.inner-circle-bottom-child > span:first-of-type{
    /* background: red; */
    margin: 0 2px;
    font-size: 1.36rem;
}


.inner-circle-line{
    width: 69%;
    height: 2px;
    background: #5fa2dd;
    margin-top: 2px;
}

.brain-image-container{
    position: absolute;
    top: 120px;
    /* left: 20px; */
}