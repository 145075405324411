.game-section-btn {
    width: max-content;
  }
  
  .game-section-btn > button {
    position: relative;
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: radial-gradient(
      98.44% 1567.43% at 97.46% 50.27%,
      #7da9f8 0%,
      #7c90f0 100%
    );
    border-radius: 23.8272px;
    border: none;
    outline: none;
    padding: 10px;
    font-family: "Circular Spotify Text";
    font-weight: 700;
    font-size: 21.2489px;
    line-height: 30px;
    color: #fff;
    width: 200px;
    box-shadow: 0px 0px 8px #7c90f0;
    z-index: 1;
  }
  .game-section-btn > button:focus,
  .game-section-btn > button:active {
    border: none;
    outline: none;
  }
  .game-section-btn > button:hover {
    opacity: 0.9;
  }
  
  .game-section-btn > button > span {
    transform: translateY(3px);
  }
  .scoreCard {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 9px 17px 9px;
    height: -moz-fit-content;
    height: fit-content;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    box-shadow: 0px 4px 13px rgb(0 0 0 / 31%);
    border-radius: 10px;
    font-weight: 400;
    font-size: 1.5rem;
    color:#fff
  }