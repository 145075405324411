.topNavBarMain{
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(39, 39, 39, 0.19);
  backdrop-filter: blur(90px);
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #1c032a00 96.2%);
}
.enablicTextHeader {
  font-family: "Circular Spotify Text";
  font-weight: 900;
  height: 64px;
  font-size: 57px;
  line-height: 73px;
  color: #ffffff;
  margin-top: 10px;
  margin-left: 13px;
  display: flex;
  align-items: center;
}

.navBarText {
  height: 64px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 12px;
  font-family: "Circular Spotify Text";
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  color: #ffffff;
}
.navBarTextAlreadyAMember {
    height: 2px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px;
    font-family: "Circular Spotify Text";
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 17px;
    color: #ffffff;
  }
.alreadyAMember {
  border: 2px solid #afd2ff;
  border-radius: 15px;
  padding: 15px;
  box-sizing: border-box;
}
.newHere {
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    98.44% 1567.43% at 97.46% 50.27%,
    #7da9f8 0%,
    #7c90f0 100%
  );
  border-radius: 15px;
  padding: 15px;
  font-family: "Circular Spotify Text";
  font-weight: 400;
  font-size: 17px;
  color: #fff;
  box-shadow: 0px 0px 8px #7c90f0;
}
@media only screen and (max-width: 950px) {
  .topNavBarMain{
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(39, 39, 39, 0.19);
    background: #e5e91b;
  }
  .enablicTextHeader {
    font-family: "Circular Spotify Text";
    font-weight: 500;
    height: 64px;
    font-size: 37px;
    line-height: 73px;
    color: #ffffff;
    margin-top: 10px;
    margin-left: 13px;
    display: flex;
    align-items: center;
  }
  
  .navBarText {
    height: 64px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 12px;
    font-family: "Circular Spotify Text";
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 400;
    font-size: 17px;
    color: #ffffff;
  }
  .navBarTextAlreadyAMember {
      height: 2px;
      cursor: pointer;
      box-sizing: border-box;
      padding: 5px;
      font-family: "Circular Spotify Text";
      display: flex;
      gap: 12px;
      align-items: center;
      font-weight: 400;
      font-size: 17px;
      color: #ffffff;
    }
  .alreadyAMember {
    border: 2px solid #afd2ff;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
  }
  .newHere {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(
      98.44% 1567.43% at 97.46% 50.27%,
      #7da9f8 0%,
      #7c90f0 100%
    );
    border-radius: 15px;
    padding: 15px;
    font-family: "Circular Spotify Text";
    font-weight: 400;
    font-size: 17px;
    color: #fff;
    box-shadow: 0px 0px 8px #7c90f0;
  }
}
