/* .ellipse1 {
  height: max(100%, 100vh);
  position: absolute;
  right: -300px;
  z-index: -100;
}
.ellipse2 {
  height: max(100%, 100vh);
  position: absolute;
  left: -200px;
  z-index: -100;
} */
@media only screen and (max-width: 600px) {
  .ellipse1 {
    display: none;
  }
  .ellipse2 {
    display: none;
  }
}
@font-face {
  font-family: "Circular Spotify Text";
  src: url(./assets/fonts/CircularSpotifyText-Black.otf);
  font-weight: 900;
}

@font-face {
  font-family: "Circular Spotify Text";
  src: url(./assets/fonts/CircularSpotifyText-Light.otf);
  font-weight: 400;
}

@font-face {
  font-family: "Circular Spotify Text";
  src: url(./assets/fonts/CircularSpotifyText-Medium.otf);
  font-weight: 600;
}
