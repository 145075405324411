.toggleMobileViewTopNavBar {
    display: none;
  }
  .toggleTopNavBar {
    display: block;
  }
  @media only screen and (max-width: 1000px) {
    .toggleMobileViewTopNavBar {
      display: block;
    }
    .toggleTopNavBar {
      display: none;
    }
  }