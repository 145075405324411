.perform-web-view {
  height: fit-content;
  width: 100vw;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.performanceSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 28px;
  gap: 10px;
  /* margin-left: 20px; */
  width: 95.2%;
  height: fit-content;
  margin-top: 30px;
  margin-bottom: 10px;
  backdrop-filter: blur(90px);
  background: linear-gradient(93.5deg, #1a0f1f 4.67%, #1c032a00 96.2%);
  border-radius: 10px;
}

.brainImg {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.perform-mobile-view {
  display: none;
}

@media (max-width: 600px) {
  .perform-web-view {
    display: none;
  }

  .perform-mobile-view {
    height: fit-content;
    width: 100vw;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .brainImg {
    width: 100%;
    height: calc(100vh - 71px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 950px) {
  .perform-web-view {
    display: none;
  }

  .perform-mobile-view {
    display: inline;
  }

  .brainImg {
    width: 100%;
    height: calc(100vh - 71px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .performanceSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 28px;
    gap: 10px;
    /* margin-left: 20px; */
    width: 94.5%;
    height: fit-content;
    margin-top: 30px;
    margin-bottom: 10px;
    backdrop-filter: blur(90px);
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #1c032a00 96.2%);
    border-radius: 10px;
  }
}