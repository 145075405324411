.insights-web-view {
  height: fit-content;
  width: 100vw;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.insightsSection {
  width: 95.2%;
  min-height: 600px;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 28px;
  gap: 10px;
  /* margin-left: 20px; */
  /* min-width: 1000px; */
  margin-top: 30px;
  margin-bottom: 10px;
  backdrop-filter: blur(10px);
  background: linear-gradient(93.5deg, #1a0f1f00 4.67%, #1c032aab 96.2%);
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  border: 1px solid #352839;
}

.insightsText {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff;
}

.insightsDropdown {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.brainImg {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.insights-mobile-view {
  display: none;
}

.scoreDistribution {
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  display: block;
  font-size: 1.5em;
  padding-left: 1.2rem;
  padding-top: 1.2rem;
  box-sizing: border-box;
  font-weight: bold;
  color: #ffffff;
}

.canvas-container {
  width: 350px;
  height: 350px;
  margin-top: 40px;
}

@media (max-width: 600px) {
  .insights-web-view {
    display: none;
  }

  .insights-mobile-view {
    height: fit-content;
    width: 100vw;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .brainImg {
    width: 100%;
    height: calc(100vh - 71px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .insightsDropdown {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
  }

  .insightsText {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #ffffff;
  }

  .scoreDistribution {
    width: 100%;
    height: 40px;
    flex-shrink: 0;
    display: block;
    font-size: 1.1em;
    padding-left: 1.2rem;
    padding-top: 1.2rem;
    box-sizing: border-box;
    font-weight: bold;
    color: #ffffff;
  }

  .canvas-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 950px) {
  .insights-web-view {
    display: none;
  }

  .insights-mobile-view {
    display: inline;
  }

  .brainImg {
    width: 100%;
    height: calc(100vh - 71px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .insightsSection {
    width: 94.5%;
  }
}