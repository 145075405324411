.Tuts {
  background-color: rgb(233, 229, 229);
  color: #000;
  border-radius: 20px;
  box-shadow: 0px 16px 42px -6px rgba(7, 21, 62, 0.502),
    0px 0px 8px -1px rgb(17, 19, 23),
    0px -6px 15px 3px rgba(25, 25, 26, 0.763) inset;
  backdrop-filter: saturate(180%) blur(60px);
  font-family: "Circular Spotify Text";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.introjs-button {
  /* width: 50px;
  height: 20px; */
  color: #fff;
  border-radius: 5px;
  padding: 15px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
}
.introjs-tooltipbuttons {
  border-top: none !important;
}
.introjs-button:focus {
  background: none;
  border: none;
  box-shadow: none;
}
.introjs-prevbutton {
  border: none;
  color: rgba(67, 67, 67, 0.731);
}
.introjs-donebutton {
  border: none;
  color: #000;
}
.introjs-donebutton:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.introjs-donebutton:hover {
  color: #000;
}
.introjs-donebutton:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.introjs-donebutton:active {
  top: 2px;
  border: none;
}

.introjs-nextbutton {
  border: none;
  color: #000;
}
.introjs-nextbutton:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.introjs-nextbutton:hover {
  color: #000;
}
.introjs-nextbutton:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.introjs-nextbutton:active {
  top: 2px;
  border: none;
}
