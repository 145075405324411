.webView {
  height: fit-content;
  width: 100vw;
  position: relative;
  overflow: hidden;
}
.mobileView {
  display: none;
}
@media (max-width: 1200px) {
  .game-section-header-content-text {
    width: 65%;
    height: 20vh;
    display: flex;
    padding: 12px;
  }
}
@media (max-width: 600px) {
  .webView {
    display: none;
  }
  .mobileView {
    display: inline;
  }
  .game-section-header-bg {
    height: 30vh;
    background: linear-gradient(
      90deg,
      rgba(104, 130, 255, 1) 0%,
      rgba(104, 130, 255, 1) 15%,
      rgba(132, 119, 222, 1) 41%,
      rgba(255, 70, 79, 0.38728995015975143) 71%
    );
    align-items: center;
    border-radius: 5px;
    margin: 5% 0 5% 0;
  }
  .game-section-header-content {
    width: 100%;
    height: 20vh;
    display: flex;
  }
  .game-section-header-content-text {
    width: 70%;
    height: 20vh;
    display: flex;
    padding: 12px;
  }
  .game-section-header-content-text-heading {
    font-family: Circular Spotify Text !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 33px !important;
    color: #ffffff !important;
  }
  .game-section-header-content-text-description {
    font-family: Circular Spotify Text !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #ffffff !important;
  }
  .game-section-header-content-text-img {
    width: 120px !important;
    height: 120px !important;
    padding: 12px !important;
    display: flex !important;
  }
  .game-section-header-bottom-text {
    font-family: Circular Spotify Text !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #ffffff !important;
    margin: 6% 0px 6% 3% !important;
  }
  .game-section-header-bottom-bg {
    width: 100%;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    border: 1px solid #352839;
    box-shadow: 0px 4px 13px rgb(0 0 0 / 31%);
  }
  .playButton {
    border-radius: 0px 0px 10.2609px 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 50% !important;
    border-left: 1px solid #584c5da8 !important;
    cursor: pointer !important;
    height: 50px !important;
  }
  .coin {
    width: 50%;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Circular Spotify Text;
    font-weight: 700;
    font-size: 14px;
    line-height: 33px;
    color: #ffffff;
  }
  .game-section-middle-parent {
    width: 90% !important;
    border-radius: 10.2609px !important;
    margin: 22px 20px !important;
    height: 100px !important;
    display: flex !important;
    overflow: hidden !important;
    border: 1px solid #4e4413 !important;
  }
  .game-section-middle-img {
    height: 100px !important;
    width: 170px !important;
    border-radius: 10.2609px 0px 0px 10.2609px;
  }
  .game-section-middle-bg {
    width: 60%;
    height: 100px;
    background: linear-gradient(93.5deg, #51355e 4.67%, #24152c 96.2%);
    border-radius: 0px 10.2609px 10.2609px 0px;
  }
  .game-section-middle-game-name {
    border-radius: 0px 10.2609px 0px 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    height: 50px !important ;
    font-family: Circular Spotify Text !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 33px !important;
    color: #ffffff !important;
    border-bottom: 1px solid #7a658370;
  }
  .game-section-middle-lower {
    border-radius: 0px 0px 10.2609px 0px;
    display: flex;
    width: 100%;
    height: 50px !important;
  }
  .frosted-glass {
    width: 90%;
    border-radius: 10.2609px;
    padding: 10px;
    margin: 22px 27px;
    background: #271d2d;
  }

  .game-section-middle-lower {
    border-radius: 0px 0px 10.2609px 0px;
    display: flex;
    width: 100%;
    height: 50px !important;
  }
  .placeholderDisp {
    width: 100%;
    height: 400px !important;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #51355e;
  }
}
@media (max-width: 950px) {
  .webView {
    display: none;
  }
  .mobileView {
    display: inline;
  }
  .game-section-header-bg {
    height: 30vh;
    background: linear-gradient(
      90deg,
      rgba(104, 130, 255, 1) 0%,
      rgba(104, 130, 255, 1) 15%,
      rgba(132, 119, 222, 1) 41%,
      rgba(255, 70, 79, 0.38728995015975143) 71%
    );
    align-items: center;
    border-radius: 5px;
    margin: 5% 0 5% 0;
  }
  .game-section-header-content {
    width: 100%;
    height: 20vh;
    display: flex;
    overflow: hidden;
  }
  .game-section-header-content-text {
    width: 65%;
    height: 20vh;
    display: flex;
    padding: 12px;
  }
  .game-section-header-content-text-heading {
    font-family: Circular Spotify Text;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #ffffff;
  }
  .game-section-header-content-text-description {
    font-family: Circular Spotify Text;
    font-weight: 400;
    font-size: 18px;
    margin-top: 17px;
    line-height: 20px;
    color: #ffffff;
  }
  .game-section-header-content-text-img {
    width: 300px;
    height: 145px;
    padding: 25px 0;
    display: flex;
  }
  .game-section-header-bottom-text {
    font-family: Circular Spotify Text;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    margin: 6% 0px 6% 3%;
  }
  .game-section-header-bottom-bg {
    display: flex;
    align-items: center;
    width: 100%;
    background: linear-gradient(93.5deg, #1a0f1f 4.67%, #24152c 96.2%);
    border: 1px solid #352839;
    box-shadow: 0px 4px 13px rgb(0 0 0 / 31%);
  }
  .playButton {
    border-radius: 0px 0px 10.2609px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 75px;
    border-left: 1px solid #584c5da8;
    cursor: pointer;
  }
  .coin {
    width: 50%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Circular Spotify Text;
    font-weight: 700;
    font-size: 17px;
    line-height: 33px;
    color: #ffffff;
  }
  .game-section-middle-parent {
    border-radius: 10.2609px;
    margin: 22px 20px;
    height: 150px;
    display: flex;
    overflow: hidden;
    border: 1px solid #4e4413;
  }
  .game-section-middle-img {
    border-radius: 10.2609px 0px 0px 10.2609px;
    width: 40%;
    height: 150px;
  }
  .game-section-middle-bg {
    width: 60%;
    height: 150px;
    background: linear-gradient(93.5deg, #51355e 4.67%, #24152c 96.2%);
    border-radius: 0px 10.2609px 10.2609px 0px;
  }
  .game-section-middle-game-name {
    border-radius: 0px 10.2609px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
    font-family: Circular Spotify Text;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    color: #ffffff;
    border-bottom: 1px solid #7a658370;
  }
  .game-section-middle-lower {
    border-radius: 0px 0px 10.2609px 0px;
    display: flex;
    width: 100%;
    height: 75px;
  }
  .frosted-glass {
    width: 90%;
    border-radius: 10.2609px;
    padding: 10px;
    margin: 22px 27px;
    background: #271d2d;
  }
  .game-section-middle-lower {
    border-radius: 0px 0px 10.2609px 0px;
    display: flex;
    width: 100%;
    height: 75px;
  }
  .placeholderDisp {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #51355e;
  }
}
@media (max-width: 380px) {
  .game-section-header-content-text-img {
    width: 100px !important;
    height: 100px !important;
    padding: 5px !important;
    display: flex !important;
  }
}
