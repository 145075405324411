.form-container {
    margin: auto;
    width: 80%;
}

.form-container-web {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    width: 850px;
    height: auto;
    background: rgba(233,233,233,0.23);
    padding: 2rem 1.5rem 2rem 1.5rem;
    border-radius: 10px;
    backdrop-filter: blur(2px);
}

.additional-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
}

.additional-details {
    width: 100%;
    height: 310px;
    background: rgba(233,233,233,0.23);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.additional-details h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #c6c5c5;
}

.autocomplete-box {
    width: 100%;
}

.avatar-modal-card {
    width: 800px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 950px) {
    /* .form-container {
        margin: auto;
    } */

    .autocomplete-box {
        width: 94.3%;
    }

    .avatar-modal-card {
        width: fit-content;
    }
}

@media (max-width: 600px) {
    .form-container {
        width: 100%;
        margin: 0;
    }

    .form-section {
        width: 100%;
    }

    .autocomplete-box {
        width: 90.65%;
    }

    .avatar-modal-card {
        width: 95vw;
        height: calc(100vh - 10%);
        display: flex;
        flex-direction: column;
        top: 50%;
    }

    .avatar-emoji {
        flex-direction: column;
    }
}
