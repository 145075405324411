.right-bar-mobile {
  width: 320px;
  padding: 0 30px;
}

.right-bar-mobile .top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.right-bar-mobile .top .right {
  display: flex;
  gap: 15px;
  align-items: center;
}

.right-bar-mobile .top .right .text .name {
  font-size: 14px;
  font-weight: 700;
}

.right-bar-mobile .top .right .text .view-profile {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
  text-align: right;
  margin-top: 7px;
  cursor: pointer;
}

.right-bar-mobile .middle-card {
  position: relative;
  background: #ff565e;
  border-radius: 25px;
  height: 150px;
  padding: 24px;
  overflow: hidden;
  margin-top: 15px;
}

.right-bar-mobile .middle-card .text {
  font-size: 20px;
  font-weight: 700;
}

.right-bar-mobile .middle-card .sub-text {
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
  width: 164px;
  line-height: 24px;
}

.right-bar-mobile .middle-card .explore {
  margin-top: 28px;
}

.right-bar-mobile .middle-card .explore>button {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 14px;
  color: #ff565e;
  cursor: pointer;
}

.right-bar-mobile .last-card {
  position: relative;
  border-radius: 25px;
  height: 250px;
  padding: 24px;
  overflow: hidden;
  background: #0073cd;
  box-shadow: 0px 1px 14px #436372;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-bar-mobile .last-card .left .top-section {
  display: flex;
  align-items: center;
  gap: 19px;
  font-size: 20px;
  font-weight: 700;
}

.right-bar-mobile .last-card .bottom {
  width: 184px;
  margin-top: 12px;
  font-size: 14px;
  line-height: 24px;
}

.right-bar-mobile .last-card .circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3ed598;
  font-size: 14px;
  position: relative;
  font-weight: 700;
}

.right-bar-mobile .last-card .circle::after {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  content: "";
  position: absolute;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.35;
}

#view-right-panel-arrow {
  display: none;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 300px;
  right: -30px;
  width: 64px;
  height: 64px;
  background: rgba(131, 101, 150, 0.31);
  border-radius: 50%;
  backdrop-filter: blur(5px);
}

@media (max-width: 1200px) {
  #view-right-panel-arrow {
    display: flex;
  }
}