@media screen and (max-width: 1000px) {
  .navbar {
    backdrop-filter: blur(26px);
    background: rgb(23 23 23 / 55%);
    height: calc(100vh - 71px);
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .listStyle {
    color: white;
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
    cursor: pointer;
  }

  .header {
    /* position: relative; */
    position: fixed;
    width: 100%;
    display: flex;
    padding: 0 12px;
    justify-content: space-between;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
  }

  .enablic_icon_mob {
    margin-right: 30px;
  }
}